<script>
import { mapGetters } from "vuex";

export default {
  components: {
    MasterDetail: () => import("@/components/master-detail.vue"),
  },
  data() {
    return {
      lastRowData: {},
    };
  },
  computed: {
    ...mapGetters(["clientId"]),
    actionBarButtons() {
      return [
        {
          text: "Atualizar",
          icon: "mdi-refresh",
          action: () => {
            this.$refs.colaboradoresTable?.doLoad();
          },
        },
      ];
    },
    columns() {
      return [
        {
          key: "nome",
          name: "Colaborador",
          truncate: true,
          width: "300px",
        },
        {
          key: "horas_trabalhadas",
          name: "Horas trabalhadas",
          type: this.$fieldTypes.DOUBLE,
          align: 1,
        },
        {
          key: "timesheets_importados",
          name: "Total timesheet\n(importados)",
          type: this.$fieldTypes.DOUBLE,
          align: 1,
        },
        {
          key: "diferenca_total",
          name: "Diferença",
          type: this.$fieldTypes.DOUBLE,
          align: 1,
        },
        {
          key: "horas_ped",
          name: "Horas em P&D\n(calculado)",
          type: this.$fieldTypes.DOUBLE,
          align: 1,
        },
        {
          key: "timesheets_elegiveis",
          name: "Horas timesheet\n(elegíveis)",
          type: this.$fieldTypes.DOUBLE,
          align: 1,
        },
        {
          key: "diferenca_elegivel",
          name: "Diferença em P&D\n(elegíveis)",
          type: this.$fieldTypes.DOUBLE,
          align: 1,
        },
      ];
    },
    contextOptions() {
      return [
        {
          name: "Visualizar projetos",
          cb: (row) => {
            this.$emit("viewDetails", row);
          },
        },
      ];
    },
    customResource() {
      const resource = this.apiResource(
        `v1/timesheet/${this.clientId}/conferenciaColaboradores`
      );
      const self = this;
      return {
        ...resource,
        get(...args) {
          return resource.get(...args).then((response) => {
            self.lastRowData = !Array.isArray(response)
              ? {}
              : response.reduce(
                  (acc, curr) => ({
                    ...acc,
                    horas_trabalhadas:
                      acc.horas_trabalhadas + curr.horas_trabalhadas,
                    timesheets_importados:
                      acc.timesheets_importados + curr.timesheets_importados,
                    diferenca_total: acc.diferenca_total + curr.diferenca_total,
                    horas_ped: acc.horas_ped + curr.horas_ped,
                    timesheets_elegiveis:
                      acc.timesheets_elegiveis + curr.timesheets_elegiveis,
                    diferenca_elegivel:
                      acc.diferenca_elegivel + curr.diferenca_elegivel,
                  }),
                  {
                    nome: "Total",
                    horas_trabalhadas: 0,
                    timesheets_importados: 0,
                    diferenca_total: 0,
                    horas_ped: 0,
                    timesheets_elegiveis: 0,
                    diferenca_elegivel: 0,
                  }
                );
            return response.map(({ nome, matricula, ...rest }) => ({
              ...rest,
              matricula,
              nome: `${matricula || "SEM MATRICULA"} — ${nome || "SEM NOME"}`,
            }));
          });
        },
      };
    },
  },
  methods: {
    doLoad() {
      this.$refs.colaboradoresTable?.doLoad();
    },
  },
};
</script>

<template>
  <v-card class="teros-elevation" :color="tableColor">
    <v-card-title class="px-5 pb-0 text-uppercase v-title-cadastro">
      Conferência Mensal de Colaboradores por Projeto
    </v-card-title>
    <MasterDetail
      ref="colaboradoresTable"
      :actionBarButtons="actionBarButtons"
      :canDelete="false"
      :canEdit="false"
      :cols="columns"
      :contextOptions="contextOptions"
      :customResource="customResource"
      disableActContextOnClick
      hasBeneficioFilter
      :hasExportCSV="false"
      :hasExportXLSX="false"
      hasMonthlyFilter
      :hasNewButton="false"
      height="calc(100vh - 300px)"
      :lastRowData="lastRowData"
    ></MasterDetail>
  </v-card>
</template>

<style lang="scss" scoped>
::v-deep {
  .v-data-table__wrapper table {
    thead tr th span {
      text-align: center;
      white-space: break-spaces;
    }

    tbody tr:not(.v-data-table__empty-wrapper) {
      cursor: auto;

      &:last-child {
        background-color: var(--v-table-header-base);
        bottom: 0;
        position: sticky;

        &.table-v-tr:hover {
          background-color: var(--v-table-header-base) !important;
        }
      }
    }
  }
}
</style>
